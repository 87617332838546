<template>
  <div
    class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
    :class="{'kt-aside--on': showMobileAside}"
    v-click-outside="onClickOutside"
    v-if="user.role.role !== 'registrar'">
      <div class="kt-aside__brand kt-grid__item " id="kt_aside_brand">
        <div class="kt-aside__brand-logo">
          <router-link to="/">
            <img alt="Logo" src="../../assets/logo.png"/>
          </router-link>
        </div>

        <div class="kt-aside__brand-tools">
          <button class="kt-aside__brand-aside-toggler" id="kt_aside_toggler" v-on:click="toggleAside">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fill-rule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "/>
                  <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "/>
                </g>
              </svg>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
        <div
          id="kt_aside_menu"
          class="kt-aside-menu"
          data-ktmenu-vertical="1"
          data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
            <ul class="kt-menu__nav">
              <template v-for="item in tabList">
                <template v-if="isAllowedRole(item.allowedRoles)">
                  <li class="kt-menu__item  kt-menu__item--submenu"
                      :class="{'kt-menu__item--open': item.isOpen, 'kt-menu__item--active' : currentPage.includes(item.route), 'kt-menu__item--disabled': item.disabled}"
                      v-if="item.isToggle && !item.isHeader"
                      aria-haspopup="true"
                      data-ktmenu-submenu-toggle="hover"
                      :key="item.index">
                    <div class="kt-menu__link kt-menu__toggle" v-on:click="toggleSubMenu(item)">
                      <span class="kt-menu__link-icon" >
                        <i class="fa" :class=item.icon></i>
                      </span>
                      <span class="kt-menu__link-text">{{$t(item.name)}}</span>
                      <i class="kt-menu__ver-arrow la la-angle-right"></i>
                    </div>
                    <div class="kt-menu__submenu ">
                      <span class="kt-menu__arrow"></span>
                      <ul class="kt-menu__subnav">
                        <template v-for="subItem in item.subMenu">
                          <li class="kt-menu__item " aria-haspopup="true" :key="subItem.index" v-if="isAllowedRole(subItem.allowedRoles)">
                            <router-link :to="subItem.route" class="kt-menu__link ">
                              <i class="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                              <span class="kt-menu__link-text">{{$t(subItem.name)}}</span>
                            </router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </li>
                  <li class="kt-menu__item"
                      aria-haspopup="true"
                      v-if="!item.isToggle && !item.isHeader"
                      :class="{'kt-menu__item--active' : item.route === currentPage, 'kt-menu__item--disabled': item.disabled}"
                      :key="item.index">
                    <router-link :to="item.route" class="kt-menu__link">
                      <span class="kt-menu__link-icon" >
                        <i class="fa" :class="item.icon"></i>
                      </span>
                      <span class="kt-menu__link-text">{{$t(item.name)}} </span>
                      <span class="kt-menu__link-badge" v-if="item.badge && user.role.role === 'admin' && badge[item.badge] > 0">
                        <span class="kt-badge kt-badge--default kt-badge--brand kt-badge--inline">{{badge[item.badge]}}</span>
                      </span>
                      <span class="kt-menu__link-badge" v-if="item.badge && user.role.role === 'organizer' && adminComments">
                        <span class="kt-badge kt-badge--default kt-badge--brand kt-badge--inline">{{adminComments}}</span>
                      </span>
                    </router-link>
                  </li>

                  <li class="kt-menu__section" v-if="item.isHeader" :key="item.index">
                    <h4 class="kt-menu__section-text">{{$t(item.name)}}</h4>
                    <i class="kt-menu__section-icon flaticon-more-v2"></i>
                  </li>
                  </template>
              </template>
            </ul>
        </div>
      </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import {getNewExceptions, getUnderModerationEvents} from "@/helpers/dashboard/dashboard";
import {getAllEvents} from "@/helpers/event/event.js";

export default {
  name: 'AsideMenu',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      badge: {
        underModerationEvents: 0,
        newExceptions: 0
      },
      adminComments: 0,
      tabList: [
        {id: 1, name: 'menu.dashboard', route: '/', icon: 'fa-chart-area', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer']}, // 'sales_manager'
        {id: 2, name: "menu.header.admin", isHeader: true, allowedRoles: ['admin']},
        {id: 3, name: 'menu.comission', route: '/comission', icon: 'fa-percent', isToggle: false, isHeader: false, allowedRoles: ['admin']},
        {id: 14, name: 'menu.currency', route: '/currency-rate', icon: 'fa-money-bill-alt', isToggle: false, isHeader: false, allowedRoles: ['admin']},
        {id: 15, name: 'menu.errors', route: '/exceptions', icon: 'fa-times', isToggle: false, isHeader: false, allowedRoles: ['admin'], badge: 'newExceptions'},
        {id: 31, name: 'menu.help', route: '/help', icon: 'fa-question-circle', isToggle: false, isHeader: false, allowedRoles: ['admin']},
        {id: 32, name: 'Emails', route: '/emails', icon: 'fa-envelope', isToggle: false, isHeader: false, allowedRoles: ['admin']},
        {id: 30, name: 'menu.advice', route: '/advices', icon: 'fa-users-cog', isToggle: false, isHeader: false, allowedRoles: ['admin']},
        {id: 4, name: "menu.header.organizer", isHeader: true, allowedRoles: ['admin']},
        {id: 4, name: "menu.header.ticket_partner", isHeader: true, allowedRoles: ['ticket_partner']},
        {id: 4, name: "menu.header.registr", isHeader: true, allowedRoles: ['registrar']},
        {id: 5, name: 'menu.events', route: '/events', icon: 'fa-calendar-alt', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer', 'ticket_partner', 'sales_manager'], badge: 'underModerationEvents'},
        {id: 6, name: 'menu.orders', route: '/orders', icon: 'fa-cart-arrow-down', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer', 'ticket_partner', 'sales_manager']},
        {id: 7, name: 'menu.exportImport', route: '/export', icon: 'fa-file-excel', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer']},
        {id: 8, name: 'menu.users', route: '/users', icon: 'fa-users', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer']},
        // {id: 30, name: 'menu.staff', icon: 'fa-users-cog', isToggle: true, isHeader: false, isOpen: false, allowedRoles: ['admin', 'organizer'],
        //   subMenu: [
        //     {id: 31, name: 'menu.staffGroup', route: '/staff-group', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false},
        //   ]
        // },
        {id: 9, name: 'menu.checkin', icon: 'fa-check-circle', isToggle: true, isHeader: false, isOpen: false, allowedRoles: ['admin', 'organizer', 'co_organizer', 'registrar'],
            subMenu: [
                {id: 10, name: 'menu.checkinStatistic', route: '/checkin-statistic', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer'], isHeader: false},
                {id: 11, name: 'menu.checkinRegistration', route: '/checkin', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer', 'registrar'], isHeader: false},
                {id: 11, name: 'menu.checkinZone', route: '/zones', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer'], isHeader: false},
                {id: 12, name: 'menu.stickers', route: '/sticker-settings', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer'], isHeader: false},
                {id: 13, name: 'menu.printers', route: '/print-settings', isToggle: false, allowedRoles: ['admin'], isHeader: false},
                {id: 14, name: 'menu.staffGroup', route: '/staff-group', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer'], isHeader: false},
            ]
        },
        {id: 16, name: 'menu.analytics', route: '/analytics-traffic', icon: 'fa-chart-line', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer', 'ticket_partner']},
        // {id: 16, name: 'menu.analytics', icon: 'fa-chart-line', isToggle: true, isHeader: false, isOpen: false, allowedRoles: ['admin', 'organizer'],
        //     subMenu: [
        //         {id: 17, name: 'menu.analyticsTraffic', route: '/analytics-traffic', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false},
        //         {id: 18, name: 'menu.analyticsPayments', route: '/analytics-payments', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false}
        //     ]
        // },
        {id: 19, name: 'menu.finances', route: '/finances', icon: 'fa-dollar-sign', isToggle: false, isHeader: false, allowedRoles: ['admin']},
        {id: 32, name: 'menu.poster', route: '/poster', icon: 'fa-calendar-alt', isToggle: false, isHeader: false, disabled: false, allowedRoles: ['admin', 'organizer']},
        {id: 20, name: 'menu.networking', route: '/networking', icon: 'fa-handshake', isToggle: false, isHeader: false, disabled: true, allowedRoles: ['admin']},
        {id: 21, name: 'menu.mail-push', icon: 'fa-cloud', isToggle: true, isHeader: false, isOpen: false, allowedRoles: ['admin', 'organizer', 'co_organizer'],
            subMenu: [
                {id: 22, name: 'menu.mailPushCreate', route: '/mail-push', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer'], isHeader: false},
                {id: 23, name: 'menu.mailPushHistory', route: '/mail-push-history', isToggle: false, allowedRoles: ['admin', 'organizer', 'co_organizer'], isHeader: false}
            ]
        },
        {id: 27, name: 'menu.trigger-messages', route: '/trigger-messages', icon: 'fa-filter', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer']},
        // {id: 28, name: 'menu.bot-menu', icon: 'fa-robot', isToggle: true, isHeader: false, isOpen: false, disabled: false, allowedRoles: ['admin'],
        //     subMenu: [
        //         {id: 29, name: 'menu.fullTelegram', route: '/bot-telegram', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false},
        //       {id: 30, name: 'menu.simpleSale', route: '/bot-sales', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false},
        //     ]
        // },
        {id: 24, name: 'menu.chat-bot', route: '/chat-bot-telegram', icon: 'fa-envelope', isToggle: false, isHeader: false, allowedRoles: ['admin', 'organizer', 'co_organizer']},
        // {id: 24, name: 'menu.chat-bot', icon: 'fa-envelope', isToggle: true, isHeader: false, isOpen: false, allowedRoles: ['admin', 'organizer'],
        //   subMenu: [
        //     {id: 25, name: 'menu.chatBotTg', route: '/chat-bot-telegram', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false},
        //     {id: 26, name: 'menu.chatBotFb', route: '/chat-bot-facebook', isToggle: false, allowedRoles: ['admin', 'organizer'], isHeader: false}
        //   ]
        // },
        // {id: 1, name: 'menu.dashboard', route: '/', icon: 'fa-chart-area', isToggle: false, isHeader: false},

        // {id: 5, name: 'menu.orders', route: '/orders', icon: 'fa-cart-arrow-down', isToggle: true, isHeader: false, isOpen: false, subMenu: [
        //         {id: 6, name: 'menu.allOrders', route: '/orders', icon: 'fa-calendar-alt', isToggle: false, isHeader: false},
        //         {id: 6, name: 'menu.createOrder', route: '/create-order', icon: 'fa-calendar-alt', isToggle: false, isHeader: false}
        //     ]
        // },
        // {id: 5, name: "Custom", route: '/', icon: 'fa-chart-area', isHeader: true},
      ]
    }
  },
  created() {
    if (this.$store.getters.user.isLogin) {
      if (this.user.role.role === 'admin') {
        getUnderModerationEvents().then(response => {
          this.badge.underModerationEvents = response.data.under_moderation
        }).catch(error => {
          this.$swal(this.$t("general.error"), error.data.message, "error")
        })
        getNewExceptions().then(response => {
          this.badge.newExceptions = response.data.errors
          this.$bus.$on('resolveException', () => {
            this.badge.newExceptions -= 1
          })
        }).catch(error => {
          this.$swal(this.$t("general.error"), error.data.message, "error")
        })
      }

      if (this.user.role.role === 'organizer') {
        this.$bus.$on('updateNotifications', this.getNotifications)
        this.getNotifications()
      }
    }
  },
  computed: {
    showMobileAside() {
      return this.$store.state.showMobileMenu;
    },
    user() {
      return this.$store.getters.user;
    },
    menuData() {
      return this.$store.getters.menuData
    },
    currentPage() {
      return this.$route.path
    }
  },
  methods: {
    getNotifications() {
      let params = {
        st_id: 1
      }
      getAllEvents(params).then(res => {
        let counter = 0
        res.data.data.forEach(event => {
          if (event.setting.is_moderated) counter++
        })
        this.adminComments = counter
      })
    },
    onClickOutside(event) {
      if(!event.target.classList.contains('kt-header-mobile__toggler--not-outside')){
        this.$store.dispatch('hideMobileAside');
      }
    },
    toggleAside() {
      this.$store.dispatch('toggleAside');
    },
    toggleSubMenu(item) {
      item.isOpen = !item.isOpen;
    },
    isAllowedRole(roles) {
      let accessTab = false;
      roles.forEach(role => {
        if (role === this.user.role.role) {
          accessTab = true
        }
      })
      return accessTab;
    }
  }
}
</script>

<style lang="sass">
.kt-aside
    height: 100vh
.kt-aside__brand-logo, .kt-aside__brand-logo a
    max-width: 100%
    text-align: center
.kt-aside__brand-logo img
    max-width: 50%
.kt-menu__nav
    max-height: calc(100vh - 90px)
    overflow-y: auto
    &::-webkit-scrollbar
        width: 7px
    &::-webkit-scrollbar-track
        background: #1e1e2d
.kt-menu__item--disabled
  pointer-events: none
  opacity: 0.5
  &:hover
    background: transparent

.kt-badge.kt-badge--default
  display: inline-flex
  justify-content: center
  align-items: center
  font-size: 11px
  line-height: 1
  font-weight: 500
  border-radius: 25px
  min-height: 25px
  min-width: 25px
  padding: 0 5px
</style>