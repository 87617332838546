<template>
  <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed " >
    <div class="kt-header-mobile__logo" style="width:100px;">
      <router-link to="/" class="mobile-logo">
        <img class="mw-100" alt="Logo" src="../../assets/logo.png"/>
      </router-link>
    </div>
    <div class="kt-header-mobile__toolbar">
      <button
        class="kt-header-mobile__toggler kt-header-mobile__toggler--left kt-header-mobile__toggler--not-outside"
        v-on:click="toggleMobileAside"
      >
        <span class="kt-header-mobile__toggler--not-outside"></span>
      </button>
<!--      <button class="kt-header-mobile__toggler"><span></span></button>-->
      <button class="kt-header-mobile__topbar-toggler" v-on:click="toggleMobileHeader"><i class="flaticon-more"></i></button>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'MobileHeader',
        methods: {
            toggleMobileHeader() {
                this.$store.dispatch('toggleMobileHeader');
            },
            toggleMobileAside() {
                this.$store.dispatch('toggleMobileAside');
            }
        }
    }
</script>