import axios from "axios";
import store from "@/store";
import errorResponseHandler from "@/helpers/responseError/errorResponseHandler";

let qs = require('qs');

const CancelToken = axios.CancelToken;
let source

export function cancelRequest() {
  if (source) source.cancel('canceled')
}

export function getAllDashboardData(meta) {
  return new Promise((res, rej) => {
    axios.post(store.state.url + '/api/v1/dashboard', meta, store.state.credentials)
      .then(function (response) {
        res({
          data: response.data
        })
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function getUnderModerationEvents() {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/dashboard/event-statistic', store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response));
            });
    });
}

export function getNewExceptions() {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/aux/exception/statistic', store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response));
            });
    });
}

export function getEventStat(params) {
    source = CancelToken.source();
    let requestData = {};
    requestData.headers = store.state.credentials.headers;
    if(params != undefined) {
        requestData.params = params;
    }
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/dashboard/event-ticket-schema-statistics', {
            headers: requestData.headers,
            params: requestData.params,
            cancelToken: source.token,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response));
            });
    });
}